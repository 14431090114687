import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/global/layout"
import PostGrid from "../components/global/PostGrid"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import Filter from "../components/pages/ingredients/Filter"

export default function ingredients({ data }) {
  const { categories, ingredients, currentCategory } = data
  return (
    <Layout>
      <SearchEngineOptimizaion
        title={
          currentCategory.nodes[0].title + " i sæson | Alfabetisk rækkefølge"
        }
        description={`Her finder du en oversigt over ${currentCategory.nodes[0].title} - samt hvornår disse ${currentCategory.nodes[0].title} er i sæson.`}
      />
      <div className="ingredientGrid">
        <div className="ingredientsHeader">
          <h1>{currentCategory.nodes[0].title}</h1>
        </div>
        <Filter categories={categories} ingredients={ingredients} />
        <div className="postGridWrapper">
          <PostGrid posts={ingredients.nodes} slugPrefix={"ingrediens"} />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($Slug: String) {
    categories: allSanityIngredientCategories {
      nodes {
        title
        slug {
          current
        }
      }
    }
    currentCategory: allSanityIngredientCategories(
      filter: { slug: { current: { eq: $Slug } } }
    ) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    ingredients: allSanityIngredients(
      filter: { category: { slug: { current: { eq: $Slug } } } }
      sort: { title: ASC }
    ) {
      nodes {
        title
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(
              width: 300
              backgroundColor: "#f9f9f9"
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`
