import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion"
import { Box } from "@chakra-ui/layout"
import { Link } from "gatsby"
import React from "react"
import { Alphabet } from "../../global/Alphabet"
import { useMediaQuery } from "@chakra-ui/react"
export default function Filter({ categories, ingredients }) {
  const alphabet = Alphabet()

  const [isLargerThan600] = useMediaQuery("(min-width: 600px)")

  return (
    <section className="ingredientFilter">
      <div className="ingredientFilterWrapper">
        <Accordion defaultIndex={isLargerThan600 ? [0, 1] : null} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton fontSize="20px">
                <Box flex="1" textAlign="left">
                  Kategori
                </Box>{" "}
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} pt={4}>
              <Link activeClassName="activatedFilter" to={`/ingredienser/`}>
                <h3 className="category">Alle</h3>
              </Link>
              {categories.nodes.map(category => {
                return (
                  <Link
                    key={category.slug.current}
                    activeClassName="activatedFilter"
                    to={`/ingredienser/${category.slug.current}/`}
                  >
                    <h3 className="category">{category.title}</h3>
                  </Link>
                )
              })}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem className="ingredientList">
            <h2>
              <AccordionButton fontSize="20px">
                <Box flex="1" textAlign="left">
                  Alfabetisk
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={10} pt={4} pl={0} pr={0}>
              {alphabet.map((letter, letterIndex) => {
                return (
                  <div key={letter + letterIndex}>
                    <h3 className="letter"> {letter}</h3>
                    <ul className="letterList">
                      {ingredients.nodes.map((ingredient, ingredientIndex) => {
                        if (ingredient.title.toLowerCase().startsWith(letter)) {
                          return (
                            <li
                              key={ingredient + ingredientIndex + letterIndex}
                            >
                              <Link
                                to={
                                  "/ingrediens/" + ingredient.slug.current + "/"
                                }
                              >
                                <div>{ingredient.title}</div>
                              </Link>
                            </li>
                          )
                        } else {
                          return ""
                        }
                      })}
                    </ul>{" "}
                  </div>
                )
              })}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}
